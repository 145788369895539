/**
 * Created by zed on 2021/9/16
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 配置文件 - 古琴汇APP
 */
const oss = `https://${process.env.VUE_APP_OSS_ENDPOINT}/${process.env.VUE_APP_SECURE}/resources`

export var IMAGES = {
  logo: {
    iconLight: `${oss}/logo_icon_light.png`,
    iconDark: `${oss}/logo_icon_dark.png`,
  },
  material: {
    menuLight: `${oss}/menu_light.png`,
    menuDark: `${oss}/menu_dark.png`,
    menuCloseLight: `${oss}/menu_close_light.png`,
  },
  titleImage: {
    contactUs: `${oss}/contact%20us.png`,
    aboutUs: `${oss}/about%20us.png`
  },
  backgroundImage: {
    partner: `${oss}/bg_partner.png`,
  },
  download: {
    qrcode: `${oss}/qrcode.png`
  }
}

/* 顶部导航栏选项，位于页眉右侧 */
export var NAV_ITEMS = [
  { title: '首页', path: '/home' },
  { title: '关于我们', path: '/about' },
  { title: '隐私政策', path: '/tos/privacy' },
  { title: '用户协议', path: '/tos/agreement' },
  { title: '联系我们', path: '/contact' },
]

/* 底部导航栏选项，位于页脚右侧 */
export var FOOTER_ITEMS = [
  { title: '关于我们', path: '/about' },
  { title: '隐私政策', path: '/tos/privacy' },
  { title: '用户协议', path: '/tos/agreement' },
  { title: '联系我们', path: '/contact' },
]

/* 二维码选项，位于页脚右侧 */
export var QRCODE_ITEMS = null
// export var QRCODE_ITEMS = [
//   { title: '客服', image: '../../src/assets/images/icon-wx1.png' },
//   { title: '公众号', image: '../../src/assets/images/icon-wx2.png' },
//   { title: '小程序', image: '../../src/assets/images/icon-wx3.png' },
// ]

export var DOWNLOAD_LINK = {
  ANDROID: 'https://www.moemone.com',
  IOS: 'https://www.moemone.com',
}
