/**
 * Created by zed on 2021/9/19
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 【页面布局】
 *  1. 安装全屏分页插件
 */

/* 安装全屏分页插件 */
import Vue from 'vue'
import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage)

export default VueFullPage
