/**
 * Created by zed on 2020/9/2
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 网络请求工具
 */
import axios from 'axios'
import qs from 'qs'
import store from '@/store'
// import Logger from '@/utils/logger'

// const logger = new Logger('【网络请求工具 | request】', Logger.INFO)

/**
 * 创建axios实例，匹配与Moemone服务器相关请求的处理
 */
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 请求地址 = 基地址（baseURL） + 请求路径（request.url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // 请求超时时间
})

/**
 * axios的请求转换器，允许在向服务器发送请求时修改数据，只能用在`PUT`、`POST`、`PATCH`上
 * 1. 针对JSON请求，将参数进行JSON序列化
 * 2. 针对FORM请求，保留参数原始数据
 * 3. 默认处理： 将参数转换成查询字符串（QueryString）
 */
axios.defaults.transformRequest = [function(data, config) {
  const contentType = config['Content-Type']
  if (!contentType) return qs.stringify(data)
  switch (contentType.toLowerCase()) {
    case 'application/json;charset=utf-8': { return JSON.stringify(data) }
    case 'multipart/form-data;charset=utf-8': { return data }
    default: { return qs.stringify(data) }
  }
}]

/**
 * 请求拦截器
 * 1. 放行时携带了请求配置参数，配置了Token的请求头信息
 * 2. 拦截了请求错误，但暂未做处理
 */
service.interceptors.request.use(
  config => {
    // if (store.getters.accessToken) {
    //   config.headers['Authorization'] = store.getters.accessToken
    //   config.headers['Institution'] = 6
    // }
    // 网站数据公开，不需要身份
    config.headers['Secure'] = process.env.VUE_APP_SECURE
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

/**
 * 响应拦截器
 * 1. 拦截自定义状态码的处理
 * 2. 拦截请求错误的提示处理
 * 3. 放行时携带了响应数据（如需要响应头或状态码，请直接返回response而不是response.data）
 */
service.interceptors.response.use(
  response => {
    // 请求完成；对自定义状态码进行分析处理
    const respData = response.data
    if (interceptMoeStatusCode(respData)) {
      return respData
    } else {
      return Promise.reject(new Error(respData.msg || '未知错误'))
    }
  },
  error => {
    // 处理非正常的状态码
    if (error.response) {
      const httpStatusCode = error.response.status
      const respData = error.response.data
      if (interceptHttpStatusCode(httpStatusCode, respData)) { return }
    }

    // 未被拦截的错误，展示错误提示，执行回调
    message.error(error.message)
    return Promise.reject(error)
  }
)

/**
 * Http状态码的拦截处理
 * 1. 400（请求参数错误），控制台输出，不提示用户
 * 2. 401（未识别用户身份），提示用户重新登录
 * 3. 403（非法访问），提示用户无权限访问
 * @param statusCode    Http状态码
 * @param responseData  响应结果数据
 * @return {boolean}    true表示拦截处理，不回调
 */
function interceptHttpStatusCode(statusCode, responseData) {
  switch (statusCode) {
    case 400:
      console.log('请求参数错误，具体描述为：' + responseData.msg)
      return false
    case 401:
      message.reLogin()
      // Todo: 尝试自动刷新Token，成功重新发送请求，失败跳转登录界面
      return false
    case 403:
      message.forbidden()
      return true
    default:
      return false
  }
}

/**
 * 响应结果的自定义状态码拦截处理
 * 1. -1（出现错误），执行错误提示
 * @param responseData  响应结果数据
 * @return {boolean}    true表示正确，false表示异常
 */
function interceptMoeStatusCode(responseData) {
  switch (responseData.code) {
    case 20000: // 成功
    case 20041: // 空数据
      return true
    case 50040:
      return true
    case 40015:
      message.error(responseData.msg)
      return false
    default:
      console.log('未处理的自定义状态码' + responseData.code)
      return false
  }
}

// let isForbiddenShow = false
const message = {
//   // 重新登录弹窗提示
//   reLogin() {
//     // logger.debug('重新登录弹窗提示')
//     MessageBox.confirm('您的登陆已失效, 点击"取消"继续留在此页面, 或点击"重新登陆"进行系统登陆。', {
//       confirmButtonText: '重新登陆',
//       cancelButtonText: '取消',
//       type: 'warning'
//     }).then(() => {
//       // Todo: 请求接口刷新Token，跳转登陆页面
//       store.dispatch('user/resetToken').then(() => { location.reload() })
//     })
//   },
//   // 非法访问弹窗提示
//   forbidden() {
//     if (isForbiddenShow === false) {
//       isForbiddenShow = true
//       MessageBox.alert('很抱歉，您不具备此内容的访问权限，请联系管理员', {
//         confirmButtonText: '确定',
//         showClose: false,
//         showCancelButton: false,
//         type: 'warning'
//       }).then(() => {
//         isForbiddenShow = false
//         // router.replace({ path: '/' })
//       }).catch(action => {
//         if (action === 'cancel') { isForbiddenShow = false }
//       })
//     }
//   },
//   // 错误小条框提示
  error(msg) {
//     Message({
//       message: msg || '未知错误',
//       type: 'error',
//       duration: 4 * 1000
//     })
  }
}

export default service
