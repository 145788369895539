/**
 * Created by zed on 2021/8/6
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 格式化工具
 */

// 手机号隐私保护
export function phonePrivacy(val) {
  if (!val) {
    return '/'
  } else {
    return val.replace(/(\d{3})(\d+)(\d{4})/, `$1${'*'.repeat(4)}$3`)
  }
}

// 身份证隐私保护
export function idNoPrivacy(val) {
  if (!val) {
    return `/`
  } else {
    return val.replace(/(\d{6})(\d+)(\d{4})/, `$1${'*'.repeat(8)}$3`)
  }
}

// 分 转 元
export function centToYuan(num) {
  return (num / 100).toFixed(2)
}

// 时间缀 转 年月日
export function intervalToYMD(timeInterval) {
  if (!timeInterval) { return null }
  return parseTime(new Date(timeInterval), '{y}-{m}-{d}')
}

/**
 * 将时间转换为字符串
 * @param time            时间对象
 * @param cFormat         格式化规划
 * @return {string|null}  时间字符串
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}
