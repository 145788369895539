/**
 * Created by zed on 2021/9/1
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 定义了本地存储的字段常量
 */

/** 企业字段 */
export const ENTERPRISE = {
  company: 'STORAGE_ENTERPRISE_COMPANY',
  slogan: 'STORAGE_ENTERPRISE_SLOGAN',
  introduce: 'STORAGE_ENTERPRISE_INTRODUCE',
  logo: 'STORAGE_ENTERPRISE_LOGO',
}

/** 站点字段 */
export const SITE = {
  name: 'STORAGE_SITE_NAME',
  brief: 'STORAGE_SITE_BRIEF',
  introduce: 'STORAGE_SITE_INTRODUCE',
  icp: 'STORAGE_SITE_ICP',
  copyright: 'STORAGE_SITE_COPYRIGHT',
  logo: 'STORAGE_SITE_LOGO',
  logoDark: 'STORAGE_SITE_LOGO_DARK',
}

/** 资源字段 */
export const RESOURCE = {
  homeBG: 'STORAGE_RESOURCE_HOME_BG',
  aboutBG: 'STORAGE_RESOURCE_ABOUT_BG',
  contactBG: 'STORAGE_RESOURCE_CONTACT_BG',
  mapIMG: 'STORAGE_RESOURCE_MAP_IMG',
  facadeIMG: 'STORAGE_RESOURCE_FACADE_IMG'
}
