/**
 * Created by zed on 2021/4/23
 * Copyright © www.moemone.com All rights reserved.
 *
 * 数据模拟器
 */
import Mock from 'mockjs'

/** 响应体格式 */
class Body {
  /**
   * 构造方法，返回响应体实例
   * @param code  自定义状态码
   * @param msg   自定义提示信息
   * @param data  自定义数据
   */
  constructor(code, msg, data) {
    this.code = code
    this.msg = msg
    this.data = data
  }

  /** 返回请求成功的响应体实例 */
  static success(data) { return new Body(20000, '成功', data) }

  /** 返回请求失败的响应体实例 */
  static error(data) { return new Body(-1, '失败', data) }
}

/** 模拟器 */
class Mocker {
  /** 模拟成功的 GET 请求 */
  static getSuccess(url, data) { Mock.mock(url, 'get', Body.success(data)) }

  /** 模拟成功的 POST 请求 */
  static postSuccess(url, data) { Mock.mock(url, 'post', Body.success(data)) }

  /** 模拟成功的 POST 请求 */
  static putSuccess(url, data) { Mock.mock(url, 'put', Body.success(data)) }

  /** 模拟成功的 POST 请求 */
  static deleteSuccess(url, data) { Mock.mock(url, 'delete', Body.success(data)) }
}
export default Mocker
