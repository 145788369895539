/**
 * Created by zed on 2021/9/6
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 【企业】API
 */
import request from "@/utils/request"

const prefix = '/api'

const api = {
  // 别名批量查询图片
  async readResource(aliases) {
    return request({
      url: `${prefix}/resource`,
      method: 'post',
      data: {
        aliases: aliases,
      }
    })
  },
  // 查询站点信息
  async readSite() {
    let siteId = process.env.VUE_APP_SITE_ID
    return request({
      url: `${prefix}/site/${siteId}`,
      method: 'get'
    })
  },
  // 查询企业信息
  async readEnterprise() {
    return request({
      url: `${prefix}/enterprise`,
      method: 'get'
    })
  },
  // 查询联系信息
  async readContact() {
    return request({
      url: `${prefix}/contact`,
      method: 'get'
    })
  },
  // 查询应用介绍
  async readIntroduce() {
    return request({
      url: `${prefix}/introduce`,
      method: 'get'
    })
  },
  // 查询合作伙伴
  async readPartners() {
    return request({
      url: `${prefix}/partners`,
      method: 'get'
    })
  },
  // 查询招聘岗位
  async readJobs() {
    return request({
      url: `${prefix}/jobs`,
      method: 'get'
    })
  },
  // 查询隐私协议
  async readPrivacy() {
    return request({
      url: `${prefix}/agreement/privacy_policy`,
      method: 'get',
    })
  },
  // 查询用户协议
  async readAgreement() {
    return request({
      url: `${prefix}/agreement/user_agreement`,
      method: 'get',
    })
  },
}

export default api
