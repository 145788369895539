/**
 * Created by zed on 2020/9/2
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 【企业】状态仓库
 */
import enterpriseApi from "@/api/enterprise"
import { SET_ENTERPRISE } from '@/store/mutation-type'
import { ENTERPRISE } from "@/utils/storage/keys"
import storage from "@/utils/storage/storage"
import { getPublicUrl } from "@/utils/common"

const state = {
  company: storage.get(ENTERPRISE.company),
  slogan: storage.get(ENTERPRISE.slogan),
  introduce: storage.get(ENTERPRISE.introduce),
  logo: storage.get(ENTERPRISE.logo),
}

const mutations = {
  [SET_ENTERPRISE]: (state, company) => {
    state.company = company.company
    state.slogan = company.slogan
    state.introduce = company.introduce
    state.logo = company.logoURL
    // 持久化存储各状态值（解决刷新后数据丢失问题）
    storage.set(ENTERPRISE.company, company.company)
    storage.set(ENTERPRISE.slogan, company.slogan)
    storage.set(ENTERPRISE.introduce, company.introduce)
    storage.set(ENTERPRISE.logo, company.logoURL)
  },
}

const actions = {
  // 获取数据
  async getInfo({ commit }) {
    try {
      let enterprise = await enterpriseApi.readEnterprise()
      console.log('【Store/Enterprise】企业信息：', enterprise.data)
      // 【状态管理】更新各状态值
      commit(SET_ENTERPRISE, enterprise.data)
    } catch (error) {
      console.log('【Store/Enterprise】获取企业数据失败：', error)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
