/**
 * Created by zed on 2020/9/3
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 *【Vuex（状态管理模式）】将项目中所有mutation（状态修改事件）常量声明于此
 */

export const SET_ENTERPRISE = 'SET_ENTERPRISE'
export const SET_RESOURCE = 'SET_RESOURCE'
export const SET_SITE = 'SET_SITE'
