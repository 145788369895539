import Vue from 'vue'
import App from './App.vue'

/* 加载SCSS样式 */
import './styles/index.scss'
/* 加载路由器 */
import router from './router'
/* 加载页面布局 */
import './layout'
/* 加载数据模拟 */
import './mock'
/* 加载Element-UI */
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
Vue.use(ElementUI, { size: 'small', zIndex: 3000 })
/* 加载Swiper（轮播插件） */
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
/* 加载公共组件 */
import Components from '@/components/app-site/index'
Vue.use(Components)
/* 加载全局过滤器 */
import * as filters from './filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
