/**
 * Created by zed on 2020/9/2
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 【资源】状态仓库
 */
import enterpriseApi from "@/api/enterprise"
import { SET_RESOURCE } from '@/store/mutation-type'
import { RESOURCE } from "@/utils/storage/keys"
import storage from "@/utils/storage/storage"

const state = {
  homeBG: storage.get(RESOURCE.homeBG),
  aboutBG: storage.get(RESOURCE.aboutBG),
  contactBG: storage.get(RESOURCE.contactBG),
  mapIMG: storage.get(RESOURCE.mapIMG),
  facadeIMG: storage.get(RESOURCE.facadeIMG)
}

const mutations = {
  [SET_RESOURCE]: (state, resource) => {
    let homeBG = resource.homeBG.imageURL
    let aboutBG = resource.aboutBG.imageURL
    let contactBG = resource.contactBG.imageURL
    let mapIMG = resource.mapIMG.imageURL
    let facadeIMG = resource.facadeIMG.imageURL
    // 更新各状态值
    state.homeBG = homeBG
    state.aboutBG = aboutBG
    state.contactBG = contactBG
    state.mapIMG = mapIMG
    state.facadeIMG = facadeIMG
    // 持久化存储各状态值（解决刷新后数据丢失问题）
    storage.set(RESOURCE.homeBG, homeBG)
    storage.set(RESOURCE.aboutBG, aboutBG)
    storage.set(RESOURCE.contactBG, contactBG)
    storage.set(RESOURCE.mapIMG, mapIMG)
    storage.set(RESOURCE.facadeIMG, facadeIMG)
  },
}

const actions = {
  // 获取数据
  async getInfo({ commit }) {
    try {
      let aliases = ['homeBG', 'aboutBG', 'contactBG', 'mapIMG', 'facadeIMG']
      const resources = await enterpriseApi.readResource(aliases)
      console.log('【Store/Resources】站点资源：', resources.data)
      // 【状态管理】更新各状态值
      commit(SET_RESOURCE, resources.data)
    } catch (error) {
      console.log('【Store/Resources】获取站点资源失败：', error)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
