/**
 * Created by zed on 2021/9/16
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 通用功能
 */

/* 版权 */
export var copyright = {
    // 跳转"备案"
    gotoBeiAn() {
        window.open('https://beian.miit.gov.cn')
    },
    // 跳转"联网备案号查询"
    gotoBeiAnQuery(code) {
        window.open(`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${code}`)
    }
}

/* 样式 */
export var style = {
    // 背景图样式
    bgImage(url) {
        return `background-image: url("${url}");`
    },
}

/**
 * 获取公共读OSS文件链接
 * @param filePath        文件的存储路径，不包含bucket名称
 * @param processConfig   图片处理参数，详见[图片处理指南](https://help.aliyun.com/document_detail/183902.htm?spm=a2c4g.11186623.0.0.59b52153AYc4Bs#section-tx1-qtj-ar8)
 * @return string         文件链接
 */
export function getPublicUrl(filePath, processConfig = '') {
    let protocol = process.env.VUE_APP_OSS_SECURE === 'true' ? 'https' : 'http'
    let host = `${process.env.VUE_APP_OSS_BUCKET}.${process.env.VUE_APP_OSS_REGION}`
    if (process.env.VUE_APP_OSS_CNAME === 'true' || !process.env.VUE_APP_OSS_ENDPOINT) {
        host = `${process.env.VUE_APP_OSS_ENDPOINT}`
    }
    let url = `${protocol}://${host}/${filePath}`
    if (processConfig && processConfig.length > 0) { url = `${url}?x-oss-process=${processConfig}` }
    return url
}
