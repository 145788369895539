/**
 * Created by zed on 2020/9/2
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 【站点】状态仓库
 */
import enterpriseApi from "@/api/enterprise"
import { SET_SITE } from '@/store/mutation-type'
import { SITE } from "@/utils/storage/keys"
import storage from "@/utils/storage/storage"

const state = {
  name: storage.get(SITE.name),
  brief: storage.get(SITE.brief),
  introduce: storage.get(SITE.introduce),
  icp: storage.get(SITE.icp),
  copyright: storage.get(SITE.copyright),
  logo: storage.get(SITE.logo),
  logoDark: storage.get(SITE.logoDark),
}

const mutations = {
  [SET_SITE]: (sate, site) => {
    state.name = site.name
    state.brief = site.brief
    state.introduce = site.introduce
    state.icp = site.icp
    state.copyright = site.copyright
    state.logo = site.logoURL
    state.logoDark = site.logoDarkURL
    // 持久化存储各状态值（解决刷新后数据丢失问题）
    storage.set(SITE.name, site.name)
    storage.set(SITE.brief, site.brief)
    storage.set(SITE.introduce, site.introduce)
    storage.set(SITE.icp, site.icp)
    storage.set(SITE.copyright, site.copyright)
    storage.set(SITE.logo, site.logoURL)
    storage.set(SITE.logoDark, site.logoDarkURL)
  }
}

const actions = {
  // 获取数据
  async getInfo({ commit }) {
    try {
      let site = await enterpriseApi.readSite()
      console.log('【Store/Site】站点信息：', site.data)
      // 【状态管理】更新各状态值
      commit(SET_SITE, site.data)
    } catch (error) {
      console.log('【Store/Site】获取站点数据失败：', error)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
