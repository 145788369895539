<!--
  -- Created by zed on 2021/9/20
  -- Copyright © 2017 www.moemone.com. All rights reserved.
  --
  -- 【导航栏】，展示企业LOGO、语言切换、菜单列表等
-->
<template>
  <div class="navigation-container">
    <!-- 左侧LOGO -->
    <div class="logo-wrap">
      <img :src="logoURL" alt="">
    </div>
    <div class="menu">
      <span v-for="(item, index) in navItems" :key="index" class="menu-item">
        <a class="el-zp" @click="moveToPage(item.anchor)">{{ item.title }}</a>
        <span v-if="index < navItems.length - 1">|</span>
      </span>
    </div>
  </div>
</template>

<script>
import { NAV_ITEMS } from "@/settings"
import store from "@/store"
import { getPublicUrl } from "@/utils/common"

export default {
  name: "NavigationBar",
  data() {
    return {
      navItems: NAV_ITEMS,
      logoURL: null,
    }
  },
  computed: {
    logo() { return store.state.enterprise.logo }
  },
  watch: {
    logo: {
      handler: function(newLogo) {
        // console.log('【NavigationBar】LOGO图变化：', newLogo)
        this.logoURL = getPublicUrl(newLogo)
      },
      immediate: true,
    }
  },
  methods: {
    moveToPage(anchor) {
      // eslint-disable-next-line no-undef
      fullpage_api.moveTo(anchor)
    }
  },
}
</script>

<style lang="scss" scoped>
.navigation-container {
  height: 4rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .logo-wrap {
    img {
      height: 100%;
    }
  }

  .menu {
    height: 100%;

    .menu-item {
      font-size: 1.1rem;
      line-height: 4rem;
      color: #999999;
      span {
        margin: 0 1rem;
      }

      &:hover {
        color: #666666;
      }
    }
  }
}
</style>
