<!--
  -- Created by zed on 2021/9/19
  -- Copyright © 2017 www.moemone.com. All rights reserved.
  --
  -- 【页面布局 > 主界面】
-->
<template>
  <div class="app-main" :style="{'min-height': mainMinHeight+'px'}">
    <!-- transition是VUE封装组件，可以给任何元素和组件添加进入/离开过渡 -->
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </div>
</template>

<script>
export default {
  name: "AppMain",
  data() {
    return {
      windowHeight: document.documentElement.clientHeight
    }
  },
  computed: {
    /* 主页面最小高度 */
    mainMinHeight() { return this.windowHeight },
    /* 将路由路径（唯一不重复）作为页面的KEY值 */
    key() { return this.$route.path }
  },
}
</script>
