/**
 * Vuex 允许我们在 store 中定义“getter”（可以认为是 store 的计算属性）。
 * 就像计算属性一样，getter 的返回值会根据它的依赖被缓存起来，且只有当它的依赖值发生了改变才会被重新计算
 * Getter 接受 state 作为其第一个参数：
 */

const getters = {
  // 企业
  enterpriseName: state => state.enterprise.company,
  enterpriseSlogan: state => state.enterprise.slogan,
  enterpriseIntroduce: state => state.enterprise.introduce,
  enterpriseLogo: state => state.enterprise.logo,
  // 资源
  resourceHomeBG: state => state.resource.homeBG,
  resourceAboutBG: state => state.resource.aboutBG,
  resourceContactBG: state => state.resource.contactBG,
  resourceMapIMG: state => state.resource.mapIMG,
  resourceFacadeIMG: state => state.resource.facadeIMG,
  // 站点
  siteName: state => state.site.name,
  siteBrief: state => state.site.brief,
  siteIntroduce: state => state.site.introduce,
  siteIcp: state => state.site.icp,
  siteCopyright: state => state.site.copyright,
  siteLogo: state => state.site.logo,
  siteLogoDark: state => state.site.logoDark,
}
export default getters
