/**
 * Created by zed on 2021/9/19
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 【路由器】
 *  1. 安装路由器
 *  2. 配置路由模块
 *  3. 构建路由器实例
 */

/* -- 安装路由器 -- */
import Vue from 'vue'
import VueRouter from "vue-router"
Vue.use(VueRouter)

/* -- 配置路由模块 -- */
import Layout from '@/layout/Layout'
// import constantRouters from "@/router/modules/app_cloudpets_net"
import constantRouters from "@/router/modules/www_guqinhub_com"

/* -- 构建路由器实例 -- */
const createRouter = () => new VueRouter({
  mode: 'history',
  routes: constantRouters
})

const router = createRouter()

export default router
