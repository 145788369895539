<!--
  -- Created by zed on 2020/9/19
  -- Copyright © 2017 www.moemone.com. All rights reserved.
  --
  -- 【页面布局】包含导航栏、主内容
-->
<template>
  <div class="app-wrapper">
    <!-- 页面主要内容 -->
    <app-main class="app-main" />
    <!-- 导航栏 -->
    <navigation-bar class="navigation-bar" />
  </div>
</template>

<script>
import NavigationBar from "@/layout/components/NavigationBar"
import AppMain from "@/layout/components/AppMain"

export default {
  name: 'Layout',
  components: { NavigationBar, AppMain },
  mixins: [],
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.app-wrapper {
  position: relative;
  width: 100%;
  min-width: 320px;

  .app-main {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .navigation-bar {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0 4rem;
  }
}
</style>
