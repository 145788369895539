/**
 * Created by zed on 2021/10/11
 * Copyright © 2017 www.moemone.com. All rights reserved.
 *
 * 【数据模拟】古琴汇 - 应用官网
 */
import Mocker from "@/mock/mocker"

const prefix = process.env.VUE_APP_BASE_API
const oss = `https://${process.env.VUE_APP_OSS_ENDPOINT}/${process.env.VUE_APP_SECURE}/resources`

// 产品介绍
Mocker.getSuccess(`${prefix}/api/introduce`, [
  {
    title: '琴馆信息数字展示',
    content: '手机实时了解琴馆师资环境，一键联系先预约，地图导航不迷路',
    image: `${oss}/icon_introduce_remote.png`,
  },
  {
    title: '琴曲演奏录制收听',
    content: '录制自己演奏的作品与琴友分享。更可收藏定制自己的琴曲乐库随时收听',
    image: `${oss}/icon_introduce_connect.png`,
  },
  {
    title: '琴课视频物料教学',
    content: '海量古琴教学视频，轻松攻克演奏难点及文学意境。随时跟名家学古琴',
    image: `${oss}/icon_introduce_app.png`,
  },
])

// 合作伙伴
Mocker.getSuccess(`${prefix}/api/partners`, null)
